
<template>
<!--single root!-->
    <div class="minHeight">
        <section>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-7 responsive-wrap">
                        <div class="row detail-filter-wrap">
                            <div class="col-md-4 featured-responsive">
                                <div class="detail-filter-text">
                                    <div class="cateTitle"> {{$i18n.locale=='zh-TW' ? cate.name : cate.name_en}}</div>
                                    <div v-if="assetQty">{{assetQty}} {{$t('resultNum')}}</div>
                                </div>
                            </div>
                            <div class="col-md-8 featured-responsive">
                                <div class="detail-filter">
                                    <p>{{$t('sort')}}</p>
                                    <!-- <form class="filter-dropdown">
                                        <input type="text">
                                    </form> -->
                                    <form class="filter-dropdown">
                                        <select v-model="experience" @change="filterData" class="custom-select  mr-sm-2 mb-sm-0" id="inlineFormCustomSelect">
                                            <option selected value="">{{$t('age')}}</option>
                                            <!-- <option value="0-99">0-99</option>
                                            <option value="100-499">100-499</option>
                                            <option value="500-999">500-999</option>
                                            <option value="1000+">1000+</option> -->
                                            <option value="1">{{$t('LH')}}</option>
                                            <option value="2">{{$t('HL')}}</option>
                                        </select>
                                    </form>
                                    <form class="filter-dropdown">
                                        <select v-model="money" @change="filterData" class="custom-select  mr-sm-2 mb-sm-0" id="inlineFormCustomSelect">
                                            <option selected value="">{{$t('price')}}</option>
                                            <!-- <option value="0-99">0-99</option>
                                            <option value="100-499">100-499</option>
                                            <option value="500-999">500-999</option>
                                            <option value="1000+">1000+</option> -->
                                            <option value="1">{{$t('LH')}}</option>
                                            <option value="2">{{$t('HL')}}</option>
                                        </select>
                                    </form>
                                    <!-- <form class="filter-dropdown">
                                        <select class="custom-select mb-2 mr-sm-2 mb-sm-0" id="inlineFormCustomSelect1">
                                            <option selected>名稱</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </form> -->
                                
                                </div>
                            </div>
                        </div>
                        <div class="al mt1">
                            <div>{{$t('search')}}： </div>
                            <input type="text" class="keywordInp"  v-model="keyword" :placeholder="$t('enterInfo')">
                            <div class="btn btn-primary" @click="filterData">{{$t('lookFor')}}</div>
                        </div>
                        <!-- <div class="row detail-checkbox-wrap">
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">

                                <label class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">寵物友好</span>
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">會使用無線互聯網GPS  </span>
                                </label>
                            </div>

                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">

                                <label class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">不抽煙  </span>
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">有大牌照</span>
                                </label>
                            </div>

                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">

                                <label class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">有小牌照</span>
                                </label>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                <label class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">可以超時</span>
                                </label>
                            </div>

                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">

                                <label class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">沒有近視</span>
                                </label>

                            </div>
                        </div> -->
                        <div class="row detail-options-wrap relative">
                            <div class="dataLoad" v-if="load"></div>
                            <h6 style="width: 100%;" class="ju" v-if="assetlist.length == 0 && !load">{{$t('noData')}}</h6>
                            <!--use v-for to render all assets!-->
                            <div v-for="(value,i) in assetlist" :key="i" 
                                :class="['workerList', 'col-sm-4', 'col-lg-12', 'col-xl-4', 'featured-responsive']">
                                <!--loop and put id in each!-->
                                <div :id="value.id" @click="displayDetail(value,i)" :class="['featured-place-wrap',{ borderAct:index==i }]">
                                        <div class="simg" v-if="value.image" :style="{ backgroundImage:`url(${value.image})` }"></div>
                                        <div class="simg ju al" v-else>
                                            <img style="height:100%;width: auto;" src="@/assets/images/user.png" />
                                        </div>
                                        <div class="featured-title-box">
                                            <h6>{{ $i18n.locale=='zh-TW' ? value.name : value.name_en }}</h6>
                                            <p>{{ $i18n.locale=='zh-TW' ? value.description : value.description_en }} </p><br/><hr/>
                                            <ul>
                                                <li> 
                                                    <!-- <font-awesome-icon :icon="['fas', 'user']" /> -->
                                                    <p>$<span>{{value.price}}</span>/ {{$t("perHours")}}</p>
                                                </li>
                                                <li> 
                                                    <!-- <font-awesome-icon :icon="['fas', 'user']" />
                                                    <p>{{$t('age')}}: {{value.experience}}</p> -->
                                                </li>
                                                <li> 
                                                    <!-- <font-awesome-icon :icon="['fas', 'user']" /> 
                                                    <p>{{$t('license')}}: </p><p>{{value.license}}</p> -->
                                                </li>
                                            </ul>
                                        </div>
                                    <!--</a>!-->
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-5 responsive-wrap border-left">
                        <div class="">
                            <!--aseet detail image!-->
                            <!-- <img :src="assetDetails.image" class="img-fluid" alt="#"> -->
                            <img v-if="assetDetails.image" :src="assetDetails.image"  style="width:80%;" class="img-fluid" alt="#">
                            <img v-else src="@/assets/images/user.png"  style="width:40%;" class="img-fluid" alt="#">
                            <div class="featured-title-box">
                                <h6>{{ $i18n.locale=='zh-TW' ? assetDetails.name : assetDetails.name_en}}</h6>
                                <p>{{ $i18n.locale=='zh-TW' ? assetDetails.description : assetDetails.description_en }}</p><br/><hr/>
                                <ul>
                                    
                                    <li>
                                        <!-- <font-awesome-icon :icon="['fas', 'user']" /> -->
                                        <p>$<span>{{assetDetails.price}}</span>/ {{$t("perHours")}}</p>
                                    </li>
                                    <li>
                                        <!-- <font-awesome-icon :icon="['fas', 'user']" /> 
                                        <p>{{$t('workE')}}: </p><p>{{assetDetails.experience}} 年</p> -->
                                    </li>
                                    <li>
                                        <!-- <font-awesome-icon :icon="['fas', 'user']" /> 
                                        <p>{{$t('license')}}: {{assetDetails.license}}</p> -->
                                    </li>
                                </ul>
                                <div class="bottom-icons">
                                    <!--<div class="open-now">{{$t('CanRent')}}</div>!-->
                                    <span class="ti-heart"></span>
                                    <span class="ti-bookmark"></span>
                                </div>
                                <br/>
                                <button class="btn btn-success " v-if="assetDetails.id">
                                    <a @click="toOrder(assetDetails)">{{$t('HireTheSailor')}}</a>
                                    <!-- <router-link :to="'/order?id=' + assetDetails.id">{{$t('HireTheSailor')}}</router-link> -->
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>



<script>
export default {
    name: 'listing',
    data () {
        return {
            msg: 'Welcome to Your Vue.js App',
            fliter: '過濾器',
            assetQty:"",
            assetlist:[],
            cataname:"",
            catadesc:"",
            assetDetails:{},
            Id: this.$route.query.id,
            imgLink: "", //image link compress by method to assign src image link
            detailImgLink: "",
            index: 0,
            keyword:"",
            money:"",
            allData: [],
            load: false,
            experience:""
        }
    },
    watch:{
        cate_id () {
            this.getData()
        }
    },
    mounted() {
        this.$store.commit("setState",{ key:"cate_id", value: this.$route.query.id*1 })

        this.getData()
    },
    methods: {
        toOrder (item) {
            if (!this.login) {
                this.$router.push("/register")
            } else {
                this.$router.push("/order?id=" + item.id)
            }
            
        },
        filterData () {            
            this.assetlist = this.allData.filter(item => {
                for (let key in item) {
                    if (String(item[key]).includes(this.keyword)) {
                        return true
                    }
                }
            })

            if (this.money == "1") {
                //低到高
                this.assetlist.sort((a,b) => {
                    return a.price- b.price
                })
                // setTimeout(() => { this.experience = "" },50)
                
            } else if (this.money == "2") {
                this.assetlist.sort((a,b) => {
                    return b.price - a.price
                })
                // setTimeout(() => { this.experience = "" },50)
                
            }
            if (this.experience == "1") {
                //低到高
                this.assetlist.sort((a,b) => {
                    return a.experience- b.experience
                })
                // setTimeout(() => { this.money = "" },50)
                
            } else if (this.experience == "2") {
                this.assetlist.sort((a,b) => {
                    return b.experience - a.experience
                })
                // setTimeout(() => { this.money = "" },50)
                
            }
        },
        getData () {
            this.load = true
            this.$http.get('service?category_id='+ this.cate_id).then((response) => {
                console.log(response)
                this.load = false
                response.data.data.forEach(item => {
                    if (item.image) {
                        item.image = item.image.split(",")[0]
                    }
                })
                this.allData = this.assetlist = response.data.data;

                // this.assetlist = this.assetlist.concat(response.data.data)
                if (this.assetlist.length) {
                    this.assetDetails = response.data.data[0];
                } else {
                    this.assetDetails = {}
                }
                
                this.assetQty = response.data.data.length;
                
            }).catch(() => {
                this.load = false
                this.allData = this.assetlist = []
            })
        },
        //update assets and category information

        displayDetail(event,i){//get asset deatil on click
            console.log(event)
            this.index = i
            // this.$http.get('service?id='+ event.id).then((response) => {
            //     // console.log(response.data)
            //     // this.detailImg(response.data.asset_id)
            //     this.assetDetails = response.data.data
            // }).catch(error => console.log(error))
            this.assetDetails = this.assetlist[i]
        },
        // detailImg: function(id){
        //     this.detailImgLink = require("@/assets/images/ppl/" + id + "_1.jpg") //build detail img
        // }
    },
    computed: {
        cate_id () { return this.$store.state.cate_id },
        cate () { return this.$store.state.cate_name },
        login () { return this.$store.state.login }
    }
}

</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.simg {
    width: 100%;
    height:16vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
/*
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}*/

.light-bg{
    background:#7EB0CE;
}

button ,a{
    color:#fff!important;
}
.minHeight {
    /* border: solid red 1px; */
    min-height: 500px;
    padding: 10px;
}
.workerList>div {
    /* border: solid red 1px; */
    box-shadow: 1px 1px 6px 0px #2b85e482;
}
.keywordInp {
    height: calc(2.25rem + 2px);
    vertical-align: bottom;
    width: 40%;
    margin-right: 3%;
    border: solid #CCC 1px;
}
.cateTitle {
    font-size: 2rem;
}
.detail-filter-text {
    border-left: solid 3px #102452;
    padding-left: 1rem;
}
.mt1 {
    margin-top: 1rem;
    padding: 1rem 0;
}
.borderAct {
    box-shadow: 0 0 0.8rem #113485 !important;
}
.toOrderBtn {
    padding: 1vw 10vw;
    background: #131D72;
    color:#FFF;
    display: none;
}






</style>